@import url("https://fonts.googleapis.com/css?family=Merriweather+Sans");
body {
  padding: 0;
  font-family: "VT323", monospace;
  color: #ffa347;
  cursor: url("../../imgs/cursor.png") 25 15, auto;
  background: url(https://scienceisbrilliant.files.wordpress.com/2013/10/black-spce.jpg);
}
section {
  cursor: default;
  overflow-x: hidden;
}
.hamburger-icon {
  height: 3vh;
}
button.open {
  position: fixed;
  background-color: hsla(0, 0%, 0%, 0);
  display: none;
  border-style: none;
  color: rgb(0, 235, 219);
}
button.open img {
  width: 2rem;
}
#ex2 {
  overflow: auto;
  background-color: rgb(88, 88, 88);
  font-size: 1.25em;
  color: rgb(0, 235, 219);
}
#ex2 li {
  list-style-type: none;
  line-height: 1.4em;
}
.high-score {
  height: 90%;
}
.high-score-list li {
  list-style-type: none;
  line-height: 1.4em;
}
.high-score-list {
  text-align: left;
  font-size: 1.75em;
}
.splashcontainer {
  width: 80vh;
  /*border: 3px solid yellow;*/
  margin: 100px auto;
}

.splashcontent {
  vertical-align: middle;
}

.separator {
  width: 50vh;
  height: 1vh;
  background-color: #ffa347;
}

.title {
  width: 50vh;
  font-size: 7em;
  font-weight: 400;
  font-family: "Bungee", cursive;
  margin: 20px auto;
  padding: auto 0px;
  margin-bottom: 127px;
  margin-top: 205px;
  position: relative;
  color: #00ebdb;
}
.alien {
  position: absolute;
  top: -123px;
  left: -1000px;
}

.hunt {
  position: absolute;
  right: -1200px;
}

.splashbuttons {
  width: 45vh;
  margin: 0 auto;
  list-style: none;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4em;
}

.splashbuttons ul {
  list-style: none;
}

.splashbutton1:hover {
  color: white;
  cursor: pointer;
}

.splashbutton2:hover {
  color: white;
  cursor: pointer;
}

.splashbutton4:hover {
  color: white;
  cursor: pointer;
}
.splashbutton5 {
  color: #ffa347;
}
.splashbutton6 {
  color: #ffa347;
}
.splashbutton5:hover {
  color: white;
  cursor: pointer;
}
.splashbutton6:hover {
  color: white;
  cursor: pointer;
}
.login {
  text-decoration: none;
  width: 45vh;
  margin: 0 auto;
  list-style: none;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4em;
  font-family: "VT323", monospace;
  color: #ffa347;
}
.login:hover {
  color: white;
  cursor: pointer;
}
.selectImg {
  width: 20px;
  height: 20px;
}

.game-over {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 100000;
  top: 0;
  display: none;
  cursor: default;
}
.game-over > img {
  width: 100%;
  height: 100vh;
}
.play-again {
  position: absolute;
  top: 66vh;
  left: 44vw;
  text-decoration: none;
  width: 45vh;
  margin: 0 auto;
  list-style: none;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4em;
  font-family: "VT323", monospace;
  color: #fff;
  cursor: pointer;
}
